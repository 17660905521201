import * as moment from 'moment';

const URLencryption = (stringPattern) => {
    let Ymd = moment().format('Ymd');
    let enYear = btoa(Ymd);
    enYear = btoa(enYear);
    let enSperate = btoa('||YLCSP01');
    let enStringPattern = btoa(stringPattern);
    let allEn = btoa(enYear + enSperate + enStringPattern);
    return  allEn;
}

const URLdecryption = (EncodeData) => {
    let allDe = atob(EncodeData);
    let enSperate = btoa('||YLCSP01');
    let allDeArr = allDe.split(enSperate);

    return atob(allDeArr[1]);
}

export const Security = {
    URLencryption: URLencryption,
    URLdecryption: URLdecryption,
}