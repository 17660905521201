import { Security } from '../../util';
import qs from 'qs';

/*
  Generated class for the ApiDriverProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
export class ApiTrackingProvider {

  constructor(private axios) {}


  public sendTracking = (qid, driverId, lat, lon, timeStamp) => new Promise(async (resolve, reject) => {

    const stringPattern = JSON.stringify({
      qid,
      driverId,
      lat,
      lon,
      timeStamp
    });

    const data = Security.URLencryption(stringPattern);

    this.axios.post(`tracking`, qs.stringify({data}))
      .then(res => {
        resolve(res.data);
      }).catch((error) => {
        reject({
          error: true,
          message: error
        });
      });
  })

}
