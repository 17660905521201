import { Injectable } from '@angular/core'
import { Geolocation, GeolocationOptions } from '@ionic-native/geolocation/ngx';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Injectable()
export class LocationService {
    subscriptionLocationService: Subscription;
    myLocation: any;
    geoOptions: GeolocationOptions;
    constructor(
        private geolocation: Geolocation,
        private platform: Platform
        ) {
        console.log('Hello LocationService Provider');
        this.geoOptions = {
            enableHighAccuracy: true,
            maximumAge: 2500,
            timeout: 3000
        };
        this.platform.ready().then(() => {
            console.log('Hello LocationService platform ready');
            this.initLocation();
        })
    }

    initLocation() {
        console.log('initLocation');
        this.unsubscribeWatch();

        this.geolocation.
        getCurrentPosition(this.geoOptions)
        .then((position) => {
            console.log('getCurrentPosition position:', position);
            if (position.coords !== undefined) {
                this.setPosition(position);
            }
        }).catch((error) => {
            console.log('error', error);
            this.showError(error);
        });
        console.log('2');

        this.subscriptionLocationService = this.geolocation.watchPosition(this.geoOptions)
                            .subscribe((position) => {
                                console.log('watchPosition position:', position);
                                if (position.coords !== undefined) {
                                    this.setPosition(position);
                                }
                            },
                            (error) => {
                                console.log('error', error);
                                this.showError(error);
                            },() => {
                                console.log('-----');
                            });
    }

    unsubscribeWatch(){
        if (this.subscriptionLocationService !== undefined) {
            this.subscriptionLocationService.unsubscribe();
            console.log('unsubscribeWatch location service !!');
        }
    }

    setPosition(position) {
        console.log('setPosition', position);
        this.myLocation = position;
        // position.coords.latitude
        // position.coords.longitude
    }

    getLocation() {
        return this.myLocation;
    }

    showError(error) {
        console.log('showError', error);
        switch (error.code) {
        case error.PERMISSION_DENIED:
            console.log('User denied the request for Geolocation.');
            break;
        case error.POSITION_UNAVAILABLE:
            console.log('Location information is unavailable.');
            break;
        case error.TIMEOUT:
            console.log('The request to get user location timed out.');
            break;
        case error.UNKNOWN_ERROR:
            console.log('An unknown error occurred.');
            break;
        }
  }
}
