import { Security } from '../../util';
import qs from 'qs';

/*
  Generated class for the ApiDriverProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
export class ApiDriverProvider {

  constructor(private axios) {}


  public authenDriver = (bookingNumber, driverCode) => new Promise(async (resolve, reject) => {

    const stringPattern = JSON.stringify({
      bookingNumber,
      driverCode
    });

    const data = Security.URLencryption(stringPattern);

    this.axios.post(`driver`,  qs.stringify({data}))
      .then(res => {
        resolve(res.data);
      }).catch((error) => {
        reject({
          error: true,
          message: error
        });
      });
  })

}
