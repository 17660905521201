import { Injectable } from '@angular/core';
import { Config } from '../config';
import { ApiDriverProvider } from './driver/driver.service';
import { ApiTrackingProvider } from './tracking/tracking.service';
import axios from 'axios';

@Injectable()
export class RequestService {

    private static driverAPI;
    private static trackingAPI;

    private api = Config.API;
    private instance: any;

    constructor() {}

    public _axios() {
        if (this.instance == null) {

            this.instance = axios.create({
                baseURL: this.api,
                timeout: 30000,
            });
        }

        this.instance.defaults.headers = this.getHeader();
        return this.instance;
    }

    private getHeader() {
        return ({
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        });
    }

    public getDriverAPI(): ApiDriverProvider {
        if (RequestService.driverAPI == null) {
            RequestService.driverAPI = new ApiDriverProvider(this._axios());
        }
        return RequestService.driverAPI;
    }

    public getTrackingAPI(): ApiTrackingProvider {
        if (RequestService.trackingAPI == null) {
            RequestService.trackingAPI = new ApiTrackingProvider(this._axios());
        }
        return RequestService.trackingAPI;
    }

}